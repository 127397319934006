:global .react-confirm-alert-overlay {
  -webkit-animation: react-confirm-alert-fadeIn 0.2s forwards !important;
  -moz-animation: react-confirm-alert-fadeIn 0.2s forwards !important;
  -o-animation: react-confirm-alert-fadeIn 0.2s forwards !important;
  animation: react-confirm-alert-fadeIn 0.2s forwards !important;
  background: $opacity-black-40 !important;
}

:global .react-confirm-alert-body {
  font-family: $font-family-base !important;
  width: 330px !important;
  color: $font-color-gray !important;
  font-size: 15px !important;
  box-shadow: 0 1px 40px rgba(0, 0, 0, 0.1) !important;
  padding: 26px !important;
}

:global .react-confirm-alert-body>h1 {
  color: $point-color !important;
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 22px;
}

:global .react-confirm-alert-button-group {
  border-top: 1px dashed $line-base !important;
  margin-top: 20px;
  padding-top: 20px;
}

:global  .react-confirm-alert-button-group>button {
  width: 50% !important;
  background-color: $point-color !important;
  font-size: 14px !important;
  padding: 10px 18px !important;
}